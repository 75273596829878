@mixin labelinput {
  color: var(--color-grey) !important;
  font-size: calc(var(--font-size-base) - 2px) !important;
  font-family: var(--font-family-base) !important;
  font-weight: 400;
}

@mixin mui-btn {
  text-transform: capitalize !important;
  font-family: var(--font-family-base) !important;
  font-weight: 400 !important;
  letter-spacing: unset !important;
  box-shadow: none !important;
}

@mixin nocontent-dropdown {
  .dropdown-toggle {
    &:after {
      content: none;
    }
  }
  .dropdown-menu {
    .dropdown-item {
      font-size: calc(var(--font-size-base) - 2px);
      color: var(--color-light-black);
      border-top: 1px solid var(--color-dark-white);
      padding: 0.5rem 1.5rem;
      &:active,
      &:focus,
      &:hover {
        background-color: transparent;
        color: var(--color-light-black);
      }
    }
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #3f8078;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3f8078;
    border: 2px solid #3f8078;
  }
}

@mixin card {
  box-shadow: 0px 1px 6px #00000029;
  border-radius: var(--border-radius-base);
  border: unset;
}

@mixin clientdetails {
  .client-details {
    dt {
      font-size: var(--font-size-base);
      color: var(--color-black);
      &:nth-child(odd) {
        font-weight: 400;
      }
      &:nth-child(even) {
        font-weight: 500;
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &.red {
        color: var(--color-red);
      }
      &.light-green {
        color: var(--color-light-green);
      }
    }
  }
}

@mixin smallscroll {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 5px;
    // background-color: var(--color-apache);
    background-color: transparent;
    background-image: linear-gradient(180deg, #ff9500 50%, #ff5e3a 100%);
  }

  &::-webkit-scrollbar-thumb {
    // background-color: var(--color-apache);
    // background-color: #ff5e3a !important;
    // // border: 2px solid var(--color-apache);
    // border: 2px solid #ff5e3a !important;
    background-color: transparent;
    background-image: linear-gradient(180deg, #ff9500 50%, #ff5e3a 100%);
  }
}

@mixin radio {
  .custom-control {
    padding-left: 0;
  }
  .custom-control-label {
    display: inline-block;
    padding: 8.6px 7px 6.2px;
    border: 1px solid var(--color-brown);
    font-size: calc(var(--font-size-base) - 2px);
    border-radius: 4px;
    transition: all 0.3s;
    min-width: 122px;
    width: 100%;
    text-align: center;
    // color: var(--color-grey);
    max-height: 38px;
    // color: #fff;
    // &:hover {
    //   //hover of price and partially fill btns
    //   // border-color: var(--color-apache);
    //   // background-color: var(--color-apache);
    //   cursor: pointer;
    // }
    &:before,
    &:after {
      content: unset;
    }
  }

  input[type="radio"] {
    display: none;
  }

  // input[type="radio"]:checked + .custom-control-label {
  //   // border-color: var(--color-apache);
  //   // background-color: var(--color-apache);
  //   border-color: #ff5e3a;
  //   background-color: transparent;
  //   background-image: linear-gradient(
  //     180deg,
  //     #ff9500 50%,
  //     #ff5e3a 100%
  //   ) !important;
  //   color: white;
  //   &:hover {
  //     border-color: #ff5e3a;
  //     background-color: transparent;
  //     background-image: linear-gradient(
  //       180deg,
  //       #ff9500 50%,
  //       #ff5e3a 100%
  //     ) !important;
  //     color: white;
  //   }
  // }
}
