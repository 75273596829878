.website-body {
  --color-white: #fff;
  --color-black: #000000;
  --color-light-black: #292929;
  --color-text-black: #191919;
  --color-sub-title-black: #313131;
  --color-green: #005348;
  // --color-apache: #DDB565;
  --color-apache: linear-gradient(180deg, #ff9500 50%, #ff5e3a 100%);
  --color-tundora: #434242;
  --color-grey: #707070;
  --color-mine-shaft: #333333;
  --color-outer-space: #252c30;
  --color-outer-space-2: #2c333b;
  --color-cinnamon: #6e4900;
  --color-mine-shaft-2: #2b2b2b;

  .white {
    color: var(--color-white);
  }

  .black {
    color: var(--color-black);
  }

  .green {
    color: var(--color-green);
  }

  .light-black {
    color: var(--color-light-black);
  }

  .text-black {
    color: var(--color-text-black);
  }

  .sub-title-black {
    color: var(--color-sub-title-black);
  }

  .tundora {
    color: var(--color-tundora);
  }

  .mine-shaft {
    color: var(--color-mine-shaft);
  }

  .apache {
    color: var(--color-apache);
  }

  .outer-space {
    color: var(--color-outer-space);
  }

  .outer-space-2 {
    color: var(--color-outer-space-2);
  }

  .bg-team {
    background-color: rgba(0, 83, 72, 0.03);
  }
}
