// @import '~bootstrap/scss/bootstrap.scss';
@import "./variables/variables.scss";
@import "./mixins/mixins.scss";
@import "./error_page.scss";

.website-body {
  font-family: var(--font-family-base);
  font-size: calc(var(--font-size-base) + 0.125rem);
  padding-top: 79.16px;
  line-height: 1.6;
  background-color: var(--color-white);
  &.ar {
    direction: rtl;
    text-align: right !important;
    font-family: "Almarai", sans-serif;
    .header {
      .navbar {
        .navbar-nav {
          .nav-link {
            font-weight: 700;
          }
        }
      }
    }
    .accordian-faq {
      .faq-ar {
        margin-left: 8px;
        margin-right: 0;
        font-family: "Almarai", sans-serif;
      }
    }
    .faqar {
      .MuiTab-textColorPrimary {
        &:last-child {
          margin-right: 16px;
        }
      }
    }
    .mobile-application {
      .green-btn {
        margin-right: 10px;
        &:first-child {
          margin-right: 0 !important;
        }
      }
    }
    .contact {
      .card-body {
        .dropzone {
          align-items: initial;
          font-family: "Almarai", sans-serif !important;
          p {
            font-family: "Almarai", sans-serif;
            font-size: var(--font-size-base);
          }
        }
      }
      aside {
        ul {
          padding-right: 0;
        }
      }
      .MuiFormLabel-root {
        font-family: "Almarai", sans-serif !important;
        input {
          font-family: "Almarai", sans-serif !important;
        }
      }
    }
  }

  .font-ar {
    font-family: "Almarai";
  }

  .error-page {
    margin-top: -79.16px;
  }

  a {
    text-decoration: none;
    &:hover,
    &:focus {
      color: var(--color-green);
    }
  }

  h2.title {
    position: relative;
    padding-left: 1rem;
    font-family: var(--font-family-montserrat);
    font-weight: 700;
    &::before {
      content: "";
      height: 100%;
      width: 5px;
      background-color: var(--color-apache);
      position: absolute;
      left: 0;
    }
  }

  h2.titlear {
    position: relative;
    padding-right: 1rem;
    font-family: "Almarai", sans-serif;
    font-weight: 700;
    text-align: "right";
    &::before {
      content: "";
      height: 100%;
      width: 5px;
      background-color: var(--color-apache);
      position: absolute;
      right: 0;
    }
  }

  .green-btn {
    background-color: var(--color-green) !important;
    color: var(--color-white) !important;
    font-size: var(--font-size-base) !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    font-family: var(--font-family-base) !important;
    letter-spacing: 0 !important;
    // padding: 0.375rem 1.5rem 0.1875rem !important;
    padding: 0.375rem 1.5rem 0.41rem !important;
  }

  .green-btnE {
    background: #ffffff26;
    /* border: 1px; */
    border-radius: 10px;
    padding: 10px;
    box-shadow: 4px 8px #3434344a;
    color: var(--color-white) !important;
    font-size: var(--font-size-base) !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    font-family: var(--font-family-base) !important;
    letter-spacing: 0 !important;
    // padding: 0.375rem 1.5rem 0.1875rem !important;
    padding: 0.375rem 1.5rem 0.41rem !important;
  }

  .green-btnar {
    background-color: var(--color-green) !important;
    color: var(--color-white) !important;
    font-size: var(--font-size-base) !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    font-family: "Almarai";
    letter-spacing: 0 !important;
    // padding: 0.375rem 1.5rem 0.1875rem !important;
    padding: 0.375rem 1.5rem 0.41rem !important;
  }

  .yellow-btn {
    // background-color: var(--color-apache) !important;
    color: var(--color-cinnamon) !important;
    font-size: var(--font-size-base) !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    font-family: var(--font-family-base) !important;
    letter-spacing: 0 !important;
    // padding: 0.375rem 1.5rem 0.1875rem !important;
    padding: 0.375rem 1.5rem 0.41rem !important;
  }

  .divi {
    margin-top: 50px;
    text-align: center;
  }

  section.mobile-application.py-5.bg {
    // background: linear-gradient(to right, #005548eb, #005548a3);
    background: linear-gradient(0deg, #b5d3d1 -7%, #e5f2f1 100%);
  }

  /*Esraa: bg for home page section bg-team*/

  .bg-teamE2 {
    background: url(../assets/img/wave.png) center center no-repeat;
    background-size: cover;
    background-color: #e5f3f1;
  }
  /** Esraa Example to for how to get started delete later**/
  section.mobile-application2.py-52.bg2 {
    background: linear-gradient(to right, #f9f5ea, #deb565);
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  section.mobile-application.py-52.bg2 {
    background-color: #e5f2f1;
    // background: linear-gradient(180deg, rgba(255, 255, 255, 0) -7%, #f3dfb69e 100%);
    padding-top: 3rem;
  }

  section.mobile-application.py-52.bg3 {
    //  background-color: #deb56524;
    background: url(../assets/img/wave.png) center center no-repeat;
    background-size: cover;
    background-color: #e5f3f1;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .css-17c6rks {
    box-sizing: border-box;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 50px;
    width: 656px;
  }

  .css-footer {
    box-sizing: border-box;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 30px;
    width: 656px;
  }

  .css-footer-color {
    color: black;
  }

  .css-2 {
    box-sizing: border-box;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 50px;
  }

  .css-16yn8ku {
    margin-left: 0px;
    margin-right: 0px;
  }

  .css-1nuzlif {
    //  height: 200px;
    margin-bottom: 24px;
    // width: 200px;
    text-align: center;
  }

  .css-10cvse8 {
    max-width: 100%;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 200px;
    width: 200px;
  }

  .css-10cvse82 {
    max-width: 100%;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // height: 100px;
    width: 80px;
  }

  .css-1syxrhr {
    font-size: 18px;
    line-height: 20px;
    text-align: center;
  }

  .css-1syxrhr {
    font-size: 18px;
    line-height: 20px;
  }

  .prices-css {
    max-width: 100%;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 350px;
    width: 350px;
  }

  .smallersizefont {
    font-size: 14px;
  }

  header {
    padding: 0;
    .lang-btn {
      font-size: var(--font-size-base);
      font-weight: 500;
      color: var(--color-black);
    }
    .hero {
      height: 500px;
      // Esraa edits for homepage banner gradiant circles
      // background: radial-gradient(circle at 48% 58%,#1ebaba 0,transparent 259px),radial-gradient(circle at 63% 46%,#deb665a6 0,transparent 446px),radial-gradient(circle at 83% 60%,rgba(30,186,186,.7) 0,transparent 175px);
      // z-index: 0;
      img {
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        height: 500px;
      }

      /*Esraa Edit: Banner text bottom padding*/
      .hero-text {
        //bottom: 3rem;
        bottom: 11rem;
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: transparent
          linear-gradient(347deg, #0c0c0ce6 0%, #ffffff99 100%) 0% 0% no-repeat
          padding-box;
        opacity: 0.25;
      }
    }

    .home-hero {
      .overlay {
        background: var(--color-black);
        opacity: 0.8;
        mix-blend-mode: soft-light;
      }
      .hero-text {
        top: 50%;
        transform: translateY(-50%);
        bottom: auto;
      }
      /**Esraa Edite */
      .hero-textEn {
        top: 50%;
        transform: translateY(-50%) translateX(25%);
        bottom: auto;
      }

      .hero-textAr {
        top: 50%;
        transform: translateY(-50%) translateX(-40%);
        bottom: auto;
      }
    }
    /**Esraa END Edite */

    .about-hero {
      .overlay {
        opacity: 0.36;
        mix-blend-mode: multiply;
      }
    }

    .marketplace-hero {
      .overlay {
        opacity: 0.31;
      }
    }

    .contact-hero {
      .overlay {
        opacity: 0.31;
      }
    }

    .navbar {
      padding: 1rem 1rem;
      border-bottom: 1px solid #ccc;
      .navbar-nav {
        .nav-link {
          color: var(--color-white);
          font-size: var(--font-size-base);
          text-transform: uppercase;
          font-weight: 500;
          &:not(:last-child) {
            &:hover,
            &.active {
              // color: var(--color-green);
              background-color: transparent;
              background-image: linear-gradient(
                180deg,
                #ff9500 50%,
                #ff5e3a 100%
              );
              -webkit-transition: all 0.3s ease-out;
              -moz-transition: all 0.3s ease-out;
              -ms-transition: all 0.3s ease-out;
              -o-transition: all 0.3s ease-out;
              transition: all 0.3s ease-out;
            }
          }
          &:not(:last-child) {
            color: var(--color-black);
          }
        }
        .nav-link.nav-btn {
          background-color: var(--color-apache);
          color: var(--color-cinnamon);
          font-weight: 700;
          min-width: 126px;
          -webkit-border-radius: 4px;
          text-align: center;
          border-radius: 4px;
          margin-left: 1.25rem;
          padding-right: 2rem;
          padding-left: 2rem;
          &:hover,
          &:focus {
            color: var(--color-cinnamon);
          }
        }
      }
    }
  }

  .platform-hero {
    //background: var(--color-green) url(../assets/img/hero-background.jpg) no-repeat center center;
    background: var(--color-green) url(../assets/img/about-us4-02.jpg) no-repeat
      center center;
    background-size: cover;
    height: 500px;
    .platform-hero-text {
      padding-bottom: 3rem;
    }
    .laptop {
      padding-bottom: 2.2rem;
    }
  }

  /*Esraa -- Added the custom design for the Arabic footer list because of the margin left and right*/
  .liAr {
    margin-left: 10rem !important;
    margin-right: 0px !important;
    text-align: right;
  }

  footer {
    //  background: linear-gradient(180deg, rgba(255, 255, 255, 0) -7%, #f3dfb69e 100%);
    //background-color: rgba(221, 181, 101, 0.07);
    /* Esraa ---- ive changed the line color of the footer */
    .link-main {
      // border-top: 1px solid #707070;
      // border-bottom: 1px solid #707070;
      border-top: 2px solid #deb565;
      border-bottom: 2px solid #deb565;
      ul.links {
        /*Esraa-- ive added this so i can fix the space between the blocks*/
        li {
          &:not(:last-child) {
            margin-right: 10rem;
          }
        }
        /*Esraa-- end*/

        a {
          font-size: calc(var(--font-size-base) + 0.125rem);
          color: var(--color-text-black);
          font-weight: 500;
          padding: 0 1.5rem;
          &:hover,
          &:focus {
            color: var(--color-green);
            text-decoration: none;
          }
        }
      }
      ul.social {
        li {
          &:not(:last-child) {
            margin-right: 1.5rem;
          }
        }
        a {
          &:hover {
            svg {
              fill: var(--color-green);
            }
          }
        }
      }
    }
  }

  #ContentWrapper {
    -webkit-transition: max-height 0.5s ease-in-out;
    -moz-transition: max-height 0.5s ease-in-out;
    -o-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out;
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .hide-content {
    width: 100%;
    overflow: hidden;
    height: auto;
    max-height: 105px;
  }

  .show-content {
    max-height: 100%;
  }

  .pioneering-kingdom {
    &.reinventing {
      background-color: rgba(221, 181, 101, 0.07);
    }
    .shadow-image {
      -webkit-box-shadow: 7px 0px 11px #00000024;
      box-shadow: 7px 0px 11px #00000024;
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
    p.content {
      font-weight: 500;
      color: var(--color-text-black);
      font-size: calc(var(--font-size-base) + 0.125rem);
      line-height: 26px;
    }

    p.smb-content {
      font-weight: 600;
      color: var(--color-text-black);
      font-size: calc(var(--font-size-base) + 0.125rem);
      line-height: 26px;
    }
  }

  .unrivalled-brokerage {
    // background: url(../assets/img/unrivalled-brokerage.jpg) center center no-repeat, var(--color-black);
    background: url(../assets/img/unrivalled-brokerage-bg.jpg) center center
        no-repeat,
      var(--color-black);
    background-size: cover;
    // background-attachment: fixed;
    .content-wrapper {
      min-height: 450px;
    }
  }

  .price-box-bg {
    background: url(../assets/img/price-bg.jpg) center center no-repeat,
      var(--color-black);
    background-size: cover;
    background-attachment: fixed;
    .content-wrapper {
      min-height: 450px;
    }
  }

  /**Esraa Addition **/
  .bannerEn {
    // background: url(../assets/img/legal-bg.jpeg) center center no-repeat, var(--color-black);
    background: url(../assets/img/homepage-bg-stat2.jpg) center center no-repeat,
      var(--color-black);
    background-size: cover;
    // background-attachment: fixed;
    .content-wrapper {
      min-height: 450px;
    }
  }

  .faq-bannerEn {
    background: url(../assets/img/faq-bg.jpg) center center no-repeat,
      var(--color-black);
    background-size: cover;
    .content-wrapper {
      min-height: 450px;
    }
  }

  .faq-bannerEAr {
    background: url(../assets/img/faq-bg-ar.jpg) center center no-repeat,
      var(--color-black);
    background-size: cover;
    .content-wrapper {
      min-height: 450px;
    }
  }

  .faq2-bannerE {
    background: url(../assets/img/faq-bg2.jpg) center center no-repeat,
      var(--color-black);
    background-size: cover;
    .content-wrapper {
      min-height: 450px;
    }
  }

  .contact-bannerE {
    background: url(../assets/img/contact-banner.jpg) center center no-repeat,
      var(--color-black);
    background-size: cover;
    .content-wrapper {
      min-height: 450px;
    }
  }

  .career-bannerEn {
    background: url(../assets/img/career-bg.jpg) right center no-repeat, #f7f7f7;
    background-size: contain;
    .content-wrapper {
      min-height: 450px;
    }
  }

  .career-bannerEAr {
    background: url(../assets/img/career-bg-ar.jpg) left center no-repeat,
      #f7f7f7;
    background-size: contain;
    .content-wrapper {
      min-height: 450px;
    }
  }

  .bannerCareerEn {
    background: url(../assets/img/bg-final.jpg) center center no-repeat,
      var(--color-black);
    background-size: cover;
    .content-wrapper {
      min-height: 450px;
    }
  }

  .bannerHome {
    background: url(../assets/img/bg-final-hp.jpg) center center no-repeat,
      var(--color-black);
    background-size: cover;
    .content-wrapper {
      min-height: 450px;
    }
  }

  .bannerCareerAr {
    background: url(../assets/img/bg-final-ar.jpg) center center no-repeat,
      var(--color-black);
    background-size: cover;
    .content-wrapper {
      min-height: 450px;
    }
  }

  .bannerPrivacy {
    background: url(../assets/img/bg-final-privacy.jpg) center center no-repeat,
      var(--color-black);
    background-size: cover;
    // background-attachment: fixed;
    .content-wrapper {
      min-height: 450px;
    }
  }

  .bannerEAr {
    background: url(../assets/img/homepage-bg-stat2-ar.jpg) center center
        no-repeat,
      var(--color-black);
    background-size: cover;
    // background-attachment: fixed;
    .content-wrapper {
      min-height: 450px;
    }
  }

  .mobile-application {
    .green-btn {
      padding: 0.5rem 1.5rem !important;
      font-family: var(--font-family-base) !important;
    }
  }

  .legal-identity {
    //  background-color: rgba(255, 255, 255, 0);
    background: url(../assets/img/desclaimer-bg-15.jpg) center center no-repeat;
    background-size: cover;
    .readlessarrow {
      -moz-transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }

  .team-members {
    .member {
      background-color: var(--color-white);
      -webkit-border-radius: 5px;
      border-radius: 5px;
      border-bottom: 2px solid transparent;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
      -moz-transform: translateY(0px);
      -webkit-transform: translateY(0px);
      -o-transform: translateY(0px);
      -ms-transform: translateY(0px);
      transform: translateY(0px);
      img {
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        -webkit-box-shadow: 4px 0px 5px #00000014;
        box-shadow: 4px 0px 5px #00000014;
        -webkit-border-radius: 5px;
        border-radius: 5px;
      }
      &:hover {
        -moz-transform: translateY(-5px);
        -webkit-transform: translateY(-5px);
        -o-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        transform: translateY(-5px);
        border-bottom: 2px solid var(--color-green);
        -webkit-box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
        box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
      }
    }
  }

  .faq {
    header.MuiAppBar-root {
      border-bottom: unset;
    }
    .MuiAppBar-colorDefault {
      background-color: var(--color-white);
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .MuiTab-root {
      padding: 6px 24px;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    .MuiTab-textColorPrimary {
      color: var(--color-green) !important;
      font-size: calc(var(--font-size-base) + 0.125rem) !important;
      font-weight: 600 !important;
      font-family: var(--font-family-montserrat) !important;
      letter-spacing: 0;
      text-transform: none;
      border-radius: 4px;
      border: 2px solid var(--color-green);
    }
    .MuiTab-textColorPrimary.Mui-selected {
      color: var(--color-white) !important;
      background-color: var(--color-green);
      border: 2px solid var(--color-green);
    }
    .MuiTabs-indicator {
      display: none;
    }
    .tab-content {
      background-color: var(--color-white);
      -webkit-box-shadow: 0px 0px 9px #36363626;
      box-shadow: 0px 0px 9px #36363626;
      padding: 1.5rem;
      margin-top: 2.5rem;
      .MuiAccordion-rounded {
        -webkit-border-radius: 3px;
        border-radius: 3px;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
      .MuiAccordion-root {
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid #eaeaea;
        &::before {
          content: unset;
        }
      }
      .MuiIconButton-root {
        -webkit-border-radius: 0;
        border-radius: 0;
        background-color: var(--color-green);
        color: var(--color-white);
        margin-right: -7px;
        padding: 3px;
        &.Mui-expanded {
          background-color: #00534847;
          color: var(--color-green);
        }
      }
      .MuiAccordionSummary-expandIcon {
        transform: none;
      }
    }
  }

  .faqar {
    header.MuiAppBar-root {
      border-bottom: unset;
    }
    .MuiAppBar-colorDefault {
      background-color: var(--color-white);
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .MuiTab-root {
      padding: 6px 24px;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    .MuiTab-textColorPrimary {
      color: var(--color-green) !important;
      font-size: calc(var(--font-size-base) + 0.125rem) !important;
      font-weight: 600 !important;
      font-family: "Almarai" !important;
      letter-spacing: 0;
      text-transform: none;
      border-radius: 4px;
      border: 2px solid var(--color-green);
    }
    .MuiTab-textColorPrimary.Mui-selected {
      color: var(--color-white) !important;
      background-color: var(--color-green);
      border: 2px solid var(--color-green);
    }
    .MuiTabs-indicator {
      display: none;
    }
    .tab-content {
      background-color: var(--color-white);
      -webkit-box-shadow: 0px 0px 9px #36363626;
      box-shadow: 0px 0px 9px #36363626;
      padding: 1.5rem;
      margin-top: 2.5rem;
      .MuiAccordion-rounded {
        -webkit-border-radius: 3px;
        border-radius: 3px;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
      .MuiAccordion-root {
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid #eaeaea;
        &::before {
          content: unset;
        }
      }
      .MuiIconButton-root {
        -webkit-border-radius: 0;
        border-radius: 0;
        background-color: var(--color-green);
        color: var(--color-white);
        margin-right: -7px;
        padding: 3px;
        &.Mui-expanded {
          background-color: #00534847;
          color: var(--color-green);
        }
      }
      .MuiAccordionSummary-expandIcon {
        transform: none;
      }
    }
  }

  .contact {
    .contact-details-wrapper {
      background-color: var(--color-white);
      -webkit-box-shadow: 0px 0px 9px #36363626;
      box-shadow: 0px 0px 9px #36363626;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      padding: 2.5rem 1.5rem;
      .contact-ul {
        li {
          margin-bottom: 0.8rem;
        }
      }
    }
    .form-group {
      margin-bottom: 1.5rem;
      .custom-file {
        height: 56px;
      }
      .custom-file-input {
        height: 56px;
        &:focus {
          box-shadow: none;
        }
      }
      .custom-file-input:focus ~ .custom-file-label {
        border: 1px solid var(--color-grey);
        box-shadow: none;
      }
      .custom-file-label {
        height: 56px;
        padding: 14.5px 14px;
        color: var(--color-tundora);
        border: 1px solid var(--color-grey);
        font-size: var(--font-size-base);
        &:after {
          background-color: transparent;
          height: 56px;
          padding: 14.5px 14px;
          border-left: none;
        }
      }
    }

    .MuiFormControl-root {
      width: 100%;
      margin: 0;
    }

    .MuiFormLabel-root {
      @include labelinput;
    }

    .MuiInputBase-root {
      color: var(--color-tundora) !important;
      font-size: var(--font-size-base) !important;
      font-family: var(--font-family-base) !important;
      letter-spacing: 0;
      input {
        @include labelinput;
      }
    }

    .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-grey);
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-green) !important;
    }

    .MuiFormLabel-root.Mui-focused {
      color: var(--color-green) !important;
    }

    .MuiTypography-body1 {
      font-size: calc(var(--font-size-base) + 0.125rem);
      font-family: var(--font-family-base) !important;
      letter-spacing: 0;
      font-weight: 500;
    }

    .MuiCheckbox-colorPrimary.Mui-checked {
      color: var(--color-green);
    }

    .dropzone {
      padding: 14.2px 20px;
      input,
      p {
        color: var(--color-tundora);
        font-weight: 500;
      }
      input {
        &::placeholder {
          color: var(--color-tundora);
          font-weight: 500;
        }
      }
      p {
        font-size: var(--font-size-base);
        font-weight: 400;
      }
    }
  }

  .content-card,
  .careers {
    .card {
      -webkit-box-shadow: 0px 0px 9px #36363626;
      box-shadow: 0px 0px 9px #36363626;
      border: 1px solid rgba(83, 84, 84, 0.125);
      ul,
      ol {
        li {
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  .careers {
    .card {
      .card {
        &:not(:first-child) {
          margin-top: 1.5rem;
        }
        -webkit-box-shadow: 0px 10px 18px #0000000a;
        box-shadow: 0px 10px 18px #0000000a;
        border-radius: 0;
        border: unset;
        .card-header {
          background-color: #f7f9f9;
          border-bottom: unset;
          color: var(--color-green);
          font-size: calc(var(--font-size-base) + 0.25rem);
          font-weight: 600;
          &:first-child {
            border-radius: 0;
          }
        }
        h3 {
          text-decoration: underline;
          font-size: calc(var(--font-size-base) + 0.25rem);
          color: var(--color-text-black);
          font-weight: 600;
          margin-bottom: 1rem;
        }
        p {
          color: var(--color-text-black);
          font-weight: 500;
          line-height: calc(var(--line-height-base) + 8px);
          font-size: calc(var(--font-size-base) + 0.125rem);
        }
        ul {
          list-style: none;
          padding-left: 1.5rem;
          margin-bottom: 0.5rem;
          li {
            color: var(--color-text-black);
            font-size: calc(var(--font-size-base) + 0.125rem);
            font-weight: 500;
            &::before {
              content: "\2B24";
              color: #e5e5e5;
              display: inline-block;
              width: 1.5rem;
              margin-left: -1.5rem;
            }
          }
        }
      }
    }
  }

  .team-behind-awraaq {
    padding-top: 3rem;
  }

  .team-member-details {
    padding: 3rem 0;
    p:last-child {
      margin-bottom: 0;
    }
  }

  .product {
    .product-img {
      background-color: var(--color-white);
      -webkit-box-shadow: 0px 0px 6px #00000029;
      box-shadow: 0px 0px 6px #00000029;
    }
    img {
      width: 880px;
    }
  }

  @media (min-width: 768px) {
    .py-5 {
      padding-top: 4.5rem !important;
      padding-bottom: 4.5rem !important;
    }
    .unrivalled-brokerage.py-5 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .pioneering-kingdom {
      .shadow-image {
        height: 100%;
      }
    }
    .team-behind-awraaq {
      padding-top: 4.5rem;
    }
    .platform-hero {
      .laptop {
        min-height: 400px;
      }
    }
  }

  @media (min-width: 992px) {
    header {
      .navbar {
        .navbar-nav {
          .nav-link {
            padding-right: 1rem;
            padding-left: 1rem;
          }
        }
      }
    }
    .platform-hero {
      .laptop {
        min-height: 500px;
      }
    }
  }

  @media (min-width: 1200px) {
    header {
      .navbar {
        .navbar-nav {
          .nav-link {
            padding-right: 1.5rem;
            padding-left: 1.5rem;
          }
        }
      }
    }
  }

  @media (min-width: 1400px) {
    .container {
      max-width: 1320px;
    }
  }

  @media (max-width: 991.98px) {
    header {
      .hero {
        height: 400px;
        .container {
          padding: 0;
        }
        img {
          height: 400px;
        }
        .hero-text {
          width: 100%;
          bottom: auto;
          top: 50%;
          transform: translateY(-50%);
          h1 {
            font-size: calc(var(--font-size-large) - 0.625rem);
          }
          p {
            font-size: calc(var(--font-size-md) - 0.375rem);
          }
          &.hero-text-2 {
            bottom: 2rem;
          }
        }
        /**Esraa Edite */
        .hero-textAr {
          width: 100%;
          bottom: auto;
          top: 50%;
          transform: translateY(-50%);
          h1 {
            font-size: calc(var(--font-size-large) - 0.625rem);
          }
          p {
            font-size: calc(var(--font-size-md) - 0.375rem);
          }
          &.hero-text-2 {
            bottom: 2rem;
          }
        }

        .hero-textEn {
          width: 100%;
          bottom: auto;
          top: 50%;
          transform: translateY(-50%);
          h1 {
            font-size: calc(var(--font-size-large) - 0.625rem);
          }
          p {
            font-size: calc(var(--font-size-md) - 0.375rem);
          }
          &.hero-text-2 {
            bottom: 2rem;
          }
        }

        /**Esraa END Edite */
      }
      .home-hero {
        .hero-text {
          bottom: auto;
        }
      }
      .navbar-collapse {
        position: fixed;
        top: 79.16px;
        bottom: 0;
        left: 100%;
        width: 100%;
        height: calc(100vh - 79.16px);
        padding-right: 1rem;
        padding-left: 1rem;
        overflow-y: auto;
        visibility: hidden;
        background-color: var(--color-black);
        -webkit-transition: transform 0.3s ease-in-out,
          visibility 0.3s ease-in-out;
        -moz-transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
        -ms-transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
        -o-transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
        &.show {
          visibility: visible;
          -moz-transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -o-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          transform: translateX(-100%);
        }
      }

      .navbar-toggler {
        border: none;
        padding: 0;
        .top {
          -webkit-transform: translateY(10.2px) rotateZ(45deg);
          -moz-transform: translateY(10.2px) rotateZ(45deg);
          -ms-transform: translateY(10.2px) rotateZ(45deg);
          -o-transform: translateY(10.2px) rotateZ(45deg);
          transform: translateY(10.2px) rotateZ(45deg);
        }
        .bottom {
          -webkit-transform: translateY(-5.2px) rotateZ(-45deg);
          -moz-transform: translateY(-5.2px) rotateZ(-45deg);
          -ms-transform: translateY(-5.2px) rotateZ(-45deg);
          -o-transform: translateY(-5.2px) rotateZ(-45deg);
          transform: translateY(-5.2px) rotateZ(-45deg);
        }
        .bar {
          display: block;
          height: 4px;
          width: 30px;
          background: #676767;
          margin: 4px 0;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          transition: all 0.5s ease;
        }
        .bar.middle {
          margin: 0 auto;
        }
        &.collapsed {
          background: none;
          .bar {
            background: #676767;
          }
          .top {
            -webkit-transform: translateY(0) rotateZ(0);
            -moz-transform: translateY(0) rotateZ(0);
            -ms-transform: translateY(0) rotateZ(0);
            -o-transform: translateY(0) rotateZ(0);
            transform: translateY(0) rotateZ(0);
          }
          .bottom {
            -webkit-transform: translateY(0) rotateZ(0);
            -moz-transform: translateY(0) rotateZ(0);
            -ms-transform: translateY(0) rotateZ(0);
            -o-transform: translateY(0) rotateZ(0);
            transform: translateY(0) rotateZ(0);
          }
          .middle {
            width: 30px;
          }
        }
        .middle {
          width: 0;
        }
      }

      .navbar {
        .navbar-nav {
          text-align: center;
          .nav-link {
            margin-top: 1.5rem;
            &:not(:last-child) {
              color: var(--color-white);
            }
          }
          .nav-btn {
            margin-left: auto !important;
            margin-right: auto;
            margin-top: 1.5rem;
            max-width: 140px;
            color: var(--color-cinnamon) !important;
            &:last-child {
              margin-top: 2rem;
            }
          }
        }
      }
    }

    .platform-hero {
      height: 400px;
      h1 {
        font-size: calc(var(--font-size-large) - 0.625rem);
      }
      p {
        font-size: calc(var(--font-size-md) - 0.375rem);
      }
    }
    header.MuiPaper-root {
      border-bottom: none;
    }
    // Esraa Mobile view
    .bannerEn {
      background: url(../assets/img/homepage-bg-stat2.jpg) center center
          no-repeat,
        var(--color-black);
      background-size: cover;
      // background-attachment: fixed;
      .content-wrapper {
        min-height: 450px;
      }
    }

    .career-bannerEn {
      background: url(../assets/img/career-bg-mobile-17.jpg) center center
          no-repeat,
        var(--color-black);
      background-size: cover;
      .content-wrapper {
        min-height: 450px;
      }
    }

    .career-bannerEAr {
      background: url(../assets/img/career-bg-mobile-17.jpg) center center
          no-repeat,
        var(--color-black);
      background-size: cover;
      .content-wrapper {
        min-height: 450px;
      }
    }

    .bannerEAr {
      background: url(../assets/img/homepage-bg-stat2.jpg) center center
          no-repeat,
        var(--color-black);
      background-size: cover;
      // background-attachment: fixed;
      .content-wrapper {
        min-height: 450px;
      }
    }

    .platform-hero {
      background: url(../assets/img/about-us4-02.jpg) center center no-repeat,
        var(--color-black);
      background-size: cover;
      // background-attachment: fixed;
      .content-wrapper {
        min-height: 450px;
      }
    }
  }

  @media (max-width: 767.98px) {
    .platform-hero {
      .platform-hero-text {
        margin-top: 2rem;
        padding-bottom: 2rem;
      }
    }
    /*Esraa -- Added the custom design for the Arabic footer list because of the margin left and right*/

    .liAr {
      margin-left: 35px !important;
      margin-right: 0px !important;
      text-align: right;
    }

    /*-------Esraa: chaged style for the footer links */
    footer {
      .link-main {
        ul.links,
        ul.social {
          // text-align: center;
          text-align: left;
        }
        ul.links {
          li {
            &:not(:last-child) {
              // margin-right: 0;
              margin-right: 35px;
            }
          }
          a {
            padding: 0.5rem;
          }
        }
      }
    }
  }

  @media (max-width: 416.98px) {
    .platform-hero {
      .platform-hero-text {
        margin-top: 5rem;
      }
    }

    footer {
      .link-main {
        ul.links {
          li {
            margin-bottom: 0.5rem;
          }
          a {
            padding: 0.4rem;
          }
        }
      }
    }
  }
  @media (max-width: 320px) {
    .platform-hero {
      .platform-hero-text {
        margin-top: 3.8rem;
      }
    }
  }
}
