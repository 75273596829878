@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Lato:wght@300;400;700;900&display=swap');

:root {
    --font-family-hind-sans-serif: "Hind", Helvetica, Arial, sans-serif;
    --font-family--lato-sans-serif: "Lato", Helvetica, Arial, sans-serif;
    --font-family-base: var(--font-family-hind-sans-serif);
    --font-family-lato: var(--font-family--lato-sans-serif);
  
    --font-size-base: 1rem;
    --font-size-large: 50px;
    --font-size-md: 30px; 
    --font-size-small: 0.875rem;
    --font-size-smaller: .75rem;
    --font-size-xs: .575rem;
    --font-size-smallest: .35rem;

    --line-height-base: 20px;
    --line-height-medium: 24px;
}

.hind{
    font-family: var(--font-family-base);   
}

.lato{
    font-family: var(--font-family-lato);
}

.hind-m{
    font-weight: 500;   
}

.hind-sb{
    font-weight: 600;   
}

.hind-b{
    font-weight: 700;   
}

.lh-18{
    line-height: calc(var(--line-height-base) - 2px);
}

.lh-20{
    line-height: var(--line-height-base);
}

.lh-24{
    line-height: calc(var(--line-height-base) + 4px);
}

.lh-64{
    line-height: 64px;
}


.fs-12{
    font-size: calc(var(--font-size-base) - 4px);
}

.fs-13{
    font-size: calc(var(--font-size-base) - 3px);
}

.fs-14{
    font-size: calc(var(--font-size-base) - 2px);
}

.fs-16{
    font-size: var(--font-size-base);
}

.fs-18{
    font-size: calc(var(--font-size-base) + 2px);
}

.fs-20{
    font-size: calc(var(--font-size-base) + 4px);
}

.fs-22{
    font-size: calc(var(--font-size-base) + 6px);
}

.fs-24{
    font-size: calc(var(--font-size-md) - 6px);
}

.fs-28{
    font-size: calc(var(--font-size-md) - 2px);
}

.fs-32{
    font-size: calc(var(--font-size-md) + 2px);
}

.fs-38{
    font-size: calc(var(--font-size-large) - 12px);
}
  