.error-page {
    .error-sub {
        min-height: var(--height-100-vh);
        background: var(--color-black);
        text-align: center;
        color: #E0E0E0;
    }

    h1 {
        font-size: 2.5rem;
    }

    div {
        transform-style: preserve-3d;
    }

    svg {
        width: clamp(300px, 70%, 600px);
        height: 300px;
    }

    #rocket {
        transform: translateY(750px);
        animation: launch 2s ease-out forwards;
    }

    @keyframes launch {
        from {
            transform: translateY(750px);
        }

        to {
            perspective: 300px;
            transform: translateY(0px);
        }
    }

    #stars {
        animation: twinkling 2s linear;
    }

    @keyframes twinkling {

        from {
            transform: scale(0);
        }

        to {
            transform: scale(1);
        }
    }

    .text {
        opacity: 0;
        animation: appear 1s ease-in forwards;
        animation-delay: 1.8s;
    }

    @keyframes appear {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    a {
        color: #F66947;
        text-decoration: none;
    }
}
