@import "icons";
@import "~bootstrap/scss/bootstrap.scss";
@import "./variables/variables.scss";
@import "./mixins/mixins.scss";
@import "./home";

body {
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  background-color: var(--body-bg);
}

// Esraa code for navigation menu arabic
.mr-3ar,
.mx-3ar {
  margin-right: 217px !important;
}

.ml-autoar,
.mx-autoar {
  margin-left: auto !important;
}

@media (max-width: 991.98px) {
  .mr-3ar,
  .mx-3ar {
    margin-right: 1rem !important;
  }

  .ml-autoar,
  .mx-autoar {
    margin-left: auto !important;
  }
}

//End of esraa code
.body-sub {
  // padding-top: 80px;
  padding-top: 100px;
}

.loader-spinner {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(65, 65, 65, 0.75);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(239, 235, 235, 0.834);
  z-index: 1;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 5px solid;
  border-color: #858586 transparent #858586 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.td-128 {
  width: 128px;
}

.volume-price-wrapper .price {
  padding-right: 1.5px;
}

.timer-wrapper span {
  display: inline-block;
  width: 82px;
}

.autocomplete {
  .MuiOutlinedInput-inputMarginDense {
    padding-top: 6.5px !important;
    padding-bottom: 2.5px !important;
  }
}

.pagination {
  margin-top: 1.5rem;

  .page-item {
    &.active .page-link {
      border-color: var(--color-green);
      background-color: var(--color-green);
    }
  }
}

.deal-not-found {
  min-height: calc(100vh - 132px);
}

img.country-flag {
  max-width: 50px;
}

.name-initial {
  margin-left: 5px;
  height: 50px;
  width: 50px;
  border: 1px solid var(--color-green);
  // background-color: rgba(0, 83, 72, 0.75);
  background-color: #00544a;

  span {
    color: var(--color-white);
  }
}

.modal-backdrop.show {
  opacity: 0.9;
}

.modal-backdrop {
  background-color: #d5d5d5;
}

.py-6 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.btn-width-148 {
  min-width: 148px !important;
}

a:hover {
  color: var(--color-brown);
}

button:focus {
  outline: none;
}

.modal {
  &.small {
    .modal-dialog {
      max-width: 354px;
    }
  }
}

.modal-content {
  border: unset;
}

.form-group {
  margin-bottom: 1.5rem;
}

.MuiInselgroup {
  .first {
    max-width: 40%;

    .MuiOutlinedInput-root {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .MuiSelect-outlined.MuiSelect-outlined {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: 37.63px;
      display: flex;
    }
  }

  .second {
    .MuiOutlinedInput-root {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 37.63px;
    }
  }
}

.MuiFormControl-root {
  width: 100%;
  margin: 0 !important;
}

.MuiFormLabel-root {
  @include labelinput;
  line-height: 1.2 !important;
}

.MuiInputBase-root {
  color: var(--color-grey) !important;
  font-size: calc(var(--font-size-base) - 2px) !important;
  font-family: var(--font-family-base) !important;

  input,
  .MuiInputAdornment-root p {
    @include labelinput;
  }
}

.MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-brown) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-brown) !important;
}

.MuiFormLabel-root.Mui-focused {
  color: var(--color-brown) !important;
}

.MuiOutlinedInput-inputMarginDense {
  // padding-top: 13px !important;
  // padding-bottom: 8px !important;
}

.orange-btn-mui {
  // background-color: var(--color-apache) !important;
  // color: var(--color-brown) !important;
  background-color: transparent;
  background-image: linear-gradient(180deg, #ff9500 50%, #ff5e3a 100%);
  @include mui-btn;
  font-weight: 600 !important;
  font-size: var(--font-size-base) !important;
  line-height: 1.64 !important;
  padding-top: 8px !important;
}

.green-btn-mui {
  background-color: var(--color-green) !important;
  @include mui-btn;
  font-weight: 600 !important;
  font-size: var(--font-size-base) !important;
  line-height: 1.64 !important;
  padding-top: 8px !important;
}

/** Esraa Addition for platform page**/
.btn-esraa {
  background: #ffffff26;
  /* border: 1px; */
  border-radius: 10px;
  padding: 10px;
  box-shadow: 5px 10px 18px #0053468c;
}

.btn-esraa2 {
  background: #005347;
  /* border: 1px; */
  border-radius: 10px;
  padding: 10px;
  /* box-shadow: 2px 6px #00534630; */
  box-shadow: 5px 10px 18px #0053468c;
}

.trans {
  color: #44786d00;
}

.light-grey-btn-mui {
  background-color: var(--color-light-grey) !important;
  color: var(--color-black) !important;
  @include mui-btn;
  font-size: var(--font-size-base) !important;
  line-height: 1.64 !important;
  padding-top: 8px !important;
}

.light-green-btn-mui {
  background-color: var(--color-light-green) !important;
  color: var(--color-white) !important;
  @include mui-btn;
  font-size: var(--font-size-base) !important;
  line-height: 1.64 !important;
  padding-top: 8px !important;

  &.fs-14 {
    font-size: calc(var(--font-size-base) - 2px);
  }
}

.red-btn-mui {
  background-color: var(--color-red) !important;
  @include mui-btn;
  font-weight: 600 !important;
  font-size: var(--font-size-base) !important;
  line-height: 1.64 !important;
  padding-top: 8px !important;
}

.disabled-btn {
  opacity: 0.5;
  cursor: not-allowed;
}

.red-btn-mui-table {
  background-color: var(--color-red) !important;
  @include mui-btn;
  font-weight: 400 !important;
  font-size: calc(var(--font-size-base) - 2px) !important;
  line-height: 1.64 !important;
  padding-top: 8px !important;
  color: var(--color-white) !important;
}

.back-btn-mui {
  text-transform: capitalize !important;
  @include mui-btn;
  color: var(--color-black) !important;
  font-weight: 600 !important;
  font-size: var(--font-size-base) !important;
}

.outline-btn-mui {
  text-transform: capitalize !important;
  @include mui-btn;
  color: var(--color-brown) !important;
  font-weight: 600 !important;
  font-size: var(--font-size-base) !important;
  border: 1px solid var(--color-apache) !important;
}

.navigation-room-btn {
  text-transform: capitalize !important;
  @include mui-btn;
  color: var(--color-brown);
  font-weight: 600;
  font-size: var(--font-size-base);
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
}

.navigation-room-btn:hover,
.navigation-room-btn:focus,
.navigation-room-btn.active {
  text-transform: capitalize !important;
  @include mui-btn;
  color: var(--color-brown);
  font-weight: 600;
  font-size: var(--font-size-base);
  border: 1px solid var(--color-apache);
  // background-color: var(--color-apache);
  background-color: transparent;
  background-image: linear-gradient(180deg, #ff9500 50%, #ff5e3a 100%);
}

.pending {
  background-color: var(--color-red);
  padding: 6px 22px 4px;
  border-radius: 19px;
}

.login {
  background-color: var(--color-apache-light);
  min-height: var(--height-100-vh);

  .login-wrapper {
    background-color: var(--color-white);
    box-shadow: 0px 2px 6px #00000061;
    border-radius: var(--border-radius-base);
    max-width: 450px;
    padding: 4rem;
  }
}

header {
  background-color: var(--color-white);
  box-shadow: 0px 3px 6px #00000012;
  padding: 3px 0;
  @include nocontent-dropdown;

  .dropdown-menu {
    box-shadow: 0px 5px 15px #00000054;
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 1rem;
    min-width: 13rem;
  }

  ul {
    .list-inline-item:last-child {
      border-left: 1px solid #d6e1e0;
    }
  }

  .institute-nav {
    padding: 0rem 1rem;

    .navbar-nav {
      .nav-link {
        font-size: 18px;
        color: var(--color-light-black);
        position: relative;

        &:last-child {
          background-color: var(--color-green);
          color: var(--color-white);
          border-radius: 4px;
          margin-left: 1rem;
          padding: 0.6rem 2rem 0.4rem;
        }

        &:not(:last-child) {
          &.active {
            color: var(--color-green);

            &:before {
              content: "";
              position: absolute;
              width: 50%;
              height: 1px;
              bottom: 0;
              left: 25%;
              border-bottom: 2px solid var(--color-green);
            }
          }
        }
      }
    }
  }
}

.sidebar {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 9999;
  height: calc(100vh - 80px);
  background-color: var(--color-green);
  overflow: auto;
  @include scrollbar;

  .sidebar-item {
    font-size: var(--font-size-base);
    color: var(--color-white);

    &.active {
      // background-color: var(--color-apache);
      background: linear-gradient(180deg, #ff9500 50%, #ff5e3a 100%);
      // background-image: linear-gradient(180deg, #ff9500 50%, #ff5e3a 100%);
    }

    .sidebar-item-content {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .sidebar-item-text {
      width: 100%;

      span {
        font-size: var(--font-size-base) !important;
        font-family: var(--font-family-base) !important;
      }
    }

    .sidebar-item-expand-arrow {
      font-size: 1.2rem !important;
      color: var(--color-white);
    }

    .sidebar-item-expand-arrow-expanded {
      color: var(--color-white);
    }
  }

  .MuiCollapse-wrapper {
    .sidebar-item {
      &.active {
        background: transparent !important;

        .sidebar-item-text {
          span {
            color: var(--color-apache);
          }
        }
      }
    }
  }
}

.main {
  position: absolute;
  top: 0;
  right: 0;
  padding-left: 0;
  padding-right: 0;
}

.dash-card-wrapper {
  .MuiPaper-elevation1 {
    box-shadow: 0px 1px 6px #00000029 !important;
  }

  .MuiPaper-rounded {
    border-radius: 10px !important;
    margin-top: 2.06rem !important;
  }

  .mt-0.MuiPaper-rounded {
    margin-top: 0 !important;
    background-color: var(--color-table-grey);
  }
}

.filters {
  .MuiInputBase-root,
  .MuiFormLabel-root {
    color: var(--color-brown) !important;
  }
}

header.MuiAppBar-root {
  padding: 0;
  background-color: transparent !important;
  box-shadow: unset;
  border-bottom: 1px solid #cbcbcb;

  .MuiButtonBase-root {
    color: var(--color-grey) !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    font-size: calc(var(--font-size-base) - 2px) !important;
    font-family: var(--font-family-base) !important;

    &.Mui-selected {
      font-weight: 500 !important;
    }
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: var(--color-green) !important;
  }

  .MuiTabs-indicator {
    background-color: var(--color-green) !important;
  }
}

.table-main-wrapper {
  border-radius: 10px !important;
  box-shadow: 0 0 6px #0000003b !important;
  @include scrollbar;

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    border-radius: var(--border-radius-base);
  }

  .MuiTablePagination-spacer {
    display: none !important;
  }

  tr.MuiTableRow-head {
    th {
      background-color: var(--color-table-grey);
    }
  }

  tbody.MuiTableBody-root {
    tr.MuiTableRow-root {
      &:hover {
        background-color: var(--color-table-grey);
      }
    }
  }

  .MuiTableCell-root {
    border-bottom: 1px solid #e5e2e2 !important;
  }

  .MuiIconButton-root {
    padding: 3px !important;
  }

  .MuiTableFooter-root {
    .MuiInputBase-input {
      padding: 10px 24px 7px 7px !important;
    }
  }

  .MuiTableCell-body {
    color: var(--color-black) !important;
  }

  .red.MuiTableCell-body {
    color: var(--color-red) !important;
  }

  .light-green.MuiTableCell-body {
    color: var(--color-light-green) !important;
  }

  table.working-deals {
    min-width: 1450px;
  }
}

.deals-detail {
  .card {
    @include card;

    .card-title {
      border-bottom: 1px solid #cbcbcb;
      position: relative;

      &:after {
        content: "";
        height: 2px;
        background-color: var(--color-green);
        width: 82px;
        position: absolute;
        left: 0;
        bottom: -1px;
      }
    }

    .card-title.large-border {
      &:after {
        width: 128px;
      }
    }

    .card-footer {
      background-color: var(--color-green);
      border-top: unset;

      &:last-child {
        border-radius: 11px;
      }
    }

    @include clientdetails;
  }
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid var(--color-brown);
  border-radius: 4px;
  background-color: var(--color-white);
  color: var(--color-grey);
  outline: none;
  transition: border 0.24s ease-in-out;
}

.active-deals {
  .card {
    @include card;

    .card-top {
      border-bottom: 1px solid #cbcbcb;
    }

    .card-bottom {
      border-top: 1px solid #cbcbcb;
    }

    ul {
      li.count-down {
        span {
          font-size: calc(var(--font-size-base) - 3px);
          color: var(--color-black);

          &.red {
            color: var(--color-red);
          }
        }
      }
    }

    @include clientdetails;

    .client-details {
      dt {
        font-size: calc(var(--font-size-base) - 2px);

        &:nth-child(even) {
          font-weight: 400;
        }

        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }

    .progress {
      height: 10px;
      background-color: #cbcbcb;
      border-radius: var(--border-radius-base);

      .progress-bar.bg-warning {
        background: transparent linear-gradient(90deg, #ab6305 0%, #ecb961 100%)
          0% 0% no-repeat padding-box;
      }

      .progress-bar.bg-success {
        background: transparent linear-gradient(90deg, #106b2b 0%, #00a130 100%)
          0% 0% no-repeat padding-box;
      }

      .progress-bar.bg-danger {
        background: transparent linear-gradient(90deg, #e67e7e 0%, #a10000 100%)
          0% 0% no-repeat padding-box;
      }
    }
  }
}

.individual-deal-main-card {
  box-shadow: 0px 0px 6px #0000003b;
  border-radius: 10px;
}

.individual-deals-data-green {
  background-color: var(--color-green);
  border-radius: var(--border-radius-base);

  .trade-details {
    ul {
      .list-inline-item {
        &:not(:last-child) {
          border-right: 2px solid var(--color-white);
        }

        &:first-child {
          border-right: unset;
        }
      }
    }
  }
}

.buy-sell-wrapper {
  .card-buy {
    @include card;
    background-color: #e8f2eb;
  }

  .card-sell {
    background-color: #fef5f5;
  }

  .final {
    background-color: var(--color-green);
    border-radius: 15px;
    padding: 6px 20px 5px;
  }

  .card-top {
    border-bottom: 1px solid var(--color-grey);
  }
}

.accept-reject {
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    height: 50px;
    border-right: 1px solid #cbcbcb;
    transform: translateY(-50%);
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    height: 50px;
    border-right: 0px solid #cbcbcb;
    transform: translateY(-50%);
  }
}

.submit-form {
  border-top: 1px solid #cbcbcb;

  .MuiFormControlLabel-root {
    .MuiTypography-body1 {
      @include labelinput;
      font-size: var(--font-size-base) !important;
      color: var(--color--light-black) !important;
    }
  }

  .green-btn-mui.MuiButton-contained.Mui-disabled {
    background-color: rgba(0, 83, 72, 0.5) !important;
  }
}

.table-card {
  border: unset;
  box-shadow: 0px 0px 6px #0000003b;
  border-radius: var(--border-radius-base);
  overflow: hidden;

  .table thead th {
    border-bottom: 1px solid #e5e2e2;
    background-color: var(--color-table-grey);
    font-weight: 500;
    padding: 0.8rem 0.75rem;
  }

  .table th,
  .table td {
    border-top: 1px solid #e5e2e2;
    color: var(--color-black);
    font-size: calc(var(--font-size-base) - 2px);
    vertical-align: middle;
  }

  .table-hover tbody tr:hover {
    color: var(--color-black);
    background-color: var(--color-table-grey);
  }

  td {
    .MuiIconButton-root {
      padding: 3px !important;
    }

    &.red {
      color: var(--color-red) !important;
    }

    &.light-green {
      color: var(--color-light-green) !important;
    }

    &.orange {
      color: var(--color-orange) !important;
    }
  }
}

.login-page {
  background-color: var(--color-apache-light);

  .MuiStepIcon-root,
  .MuiStepConnector-line {
    color: #cbcbcb !important;
  }

  .MuiStepConnector-lineHorizontal {
    border-top-width: 5px !important;
  }

  .MuiStepConnector-alternativeLabel {
    top: 10px !important;
    left: calc(-50% + 12px) !important;
    right: calc(50% + 12px) !important;
  }

  .MuiStepIcon-root.MuiStepIcon-active,
  .MuiStepIcon-root.MuiStepIcon-completed {
    // color: var(--color-apache) !important;
    color: #ff7a1d !important;
    //  border-color: #ff5e3a;
    // background-color: transparent;
    // background-image: linear-gradient(
    //   180deg,
    //   #ff9500 50%,
    //   #ff5e3a 100%
    // ) !important;
  }

  .MuiStepConnector-completed .MuiStepConnector-line {
    border-color: var(--color-apache) !important;
  }

  .login-form {
    .login-form-sub {
      min-width: 386px;
      max-width: 386px;
    }
  }

  .right-img {
    background: url(../assets/img/login-bg.svg) no-repeat;
    background-size: cover;
    background-color: var(--color-green);
  }

  .register {
    width: 85%;
  }

  .signup-content {
    height: 382px;
    padding-right: 0.5rem;
    overflow-y: auto;
    @include smallscroll;

    hr {
      margin-bottom: 1.1rem;
    }
  }

  .checkbox {
    .MuiSvgIcon-fontSizeSmall {
      font-size: 1rem !important;
    }

    .MuiTypography-body1 {
      font-family: var(--font-family-lato);
      font-size: var(--font-size-base) !important;
      color: var(--color--light-black);
    }
  }
}

.order-details {
  @include radio;

  .buy {
    .custom-control-label:hover {
      border-color: var(--color-light-green);
      background-color: var(--color-light-green);
      cursor: pointer;
      color: var(--color-white);
    }

    input[type="radio"]:checked + .custom-control-label {
      border-color: var(--color-light-green);
      background-color: var(--color-light-green);
      color: var(--color-white);
    }
  }

  .sell {
    .custom-control-label:hover {
      border-color: var(--color-red);
      background-color: var(--color-red);
      cursor: pointer;
      color: var(--color-white);
    }

    input[type="radio"]:checked + .custom-control-label {
      border-color: var(--color-red);
      background-color: var(--color-red);
      color: var(--color-white);
    }
  }
  .open {
    // border-color: #ff5e3a;
    // background-color: transparent;
    // background-image: linear-gradient(
    //   180deg,
    //   #ff9500 50%,
    //   #ff5e3a 100%
    // ) !important;
    // color: white !important;

    // &:hover {
    //   border-color: #ff9500 !important; // Added !important
    //   background-color: #ff5e3a !important; // Added !important
    //   color: white !important;
    // }
    .custom-control-label:hover {
      border-color: #ff5e3a;
      background-color: transparent;
      background-image: linear-gradient(
        180deg,
        #ff9500 50%,
        #ff5e3a 100%
      ) !important;
      color: white !important;
      cursor: pointer;
    }

    input[type="radio"]:checked + .custom-control-label {
      border-color: #ff5e3a;
      background-color: transparent;
      background-image: linear-gradient(
        180deg,
        #ff9500 50%,
        #ff5e3a 100%
      ) !important;
      color: white !important;
    }
  }
  .limit {
    .custom-control-label:hover {
      border-color: #ff5e3a;
      background-color: transparent;
      background-image: linear-gradient(
        180deg,
        #ff9500 50%,
        #ff5e3a 100%
      ) !important;
      color: white !important;
      cursor: pointer;
    }

    input[type="radio"]:checked + .custom-control-label {
      border-color: #ff5e3a;
      background-color: transparent;
      background-image: linear-gradient(
        180deg,
        #ff9500 50%,
        #ff5e3a 100%
      ) !important;
      color: white !important;
    }
  }
}

.card-info {
  box-shadow: 0px 0px 6px #0000003b;
  min-height: 224.6px;

  .card-body {
    background: transparent linear-gradient(113deg, #ffecf9 0%, #5d47d1 100%) 0%
      0% no-repeat padding-box;
    border-radius: var(--border-radius-base);

    .info-title {
      border-radius: 19px;
      background-color: rgba(255, 255, 255, 0.4);
      padding: 8px 0 9px;
    }
  }
}

.card-info.card-billing {
  .card-body {
    background: transparent linear-gradient(123deg, #ffd1d1 0%, #66e242 100%) 0%
      0% no-repeat padding-box;
  }
}

.change-password {
  .change-password-sub {
    min-height: calc(100vh - 80px);
  }

  .change-password-form {
    background-color: var(--color-white);
    box-shadow: 0px 0px 6px #0000003b;
    border-radius: var(--border-radius-base);
    max-width: 400px;
    padding: 2rem;
  }
}

.submit-final-off {
  .MuiFormControlLabel-root {
    margin-right: 0;
  }
}

// Media Query
@media (min-width: 1200px) {
  .row-cols-xl-7 > * {
    flex: 0 0 14.2857%;
    max-width: 14.2857%;
  }

  .individual-deals-data-green {
    .price-details {
      border-left: 2px solid var(--color-white);
      border-right: 2px solid var(--color-white);
    }
  }
}

@media all and (min-width: 992px) {
  .offertypegreenbtn {
    flex: 0 0 12.7%;
    max-width: 12.7%;
  }

  .sidebar {
    width: 243px;
  }

  .main {
    min-width: calc(100% - 243px);
  }

  .login-page {
    .login-form,
    .right-img {
      min-height: var(--height-100-vh);
    }
  }

  header {
    .institute-nav {
      .navbar-nav {
        .nav-link {
          padding-right: 1rem;
          padding-left: 1rem;
        }
      }
    }
  }

  .buy-sell-wrapper {
    .border-card {
      &::before {
        content: "";
        border-left: 1px solid #cbcbcb;
        position: absolute;
        left: -1.5rem;
        height: 60%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

@media all and (min-width: 600px) {
  .table-main-wrapper {
    .MuiToolbar-gutters {
      padding-left: 15px !important;
    }
  }
}

@media all and (max-width: 1366px) {
  .institutional-deals-table {
    min-width: 1550px;
  }
}

@media all and (max-width: 1199px) {
  .individual-deals-data-green {
    .price-details {
      border-top: 2px solid var(--color-white);
      border-bottom: 2px solid var(--color-white);
    }
  }

  .dashboard-deal-table {
    min-width: 1200px;
  }
}

@media all and (max-width: 991px) {
  .sidebar {
    width: 180px;
  }

  .main {
    min-width: calc(100% - 180px);
  }

  .trade-details {
    border-top: 2px solid var(--color-white);

    ul {
      .list-inline-item {
        padding: 0;
      }
    }
  }

  .login-page {
    .login-form,
    .right-img {
      padding: 6rem 0;
    }
  }

  header {
    .institute-nav {
      .navbar-nav {
        .nav-link {
          font-size: 14px;
          padding-right: 0.3rem;
          padding-left: 0.3rem;

          &:last-child {
            margin-left: 0.5rem;
            padding: 0.6rem 1rem 0.4rem;
          }
        }
      }
    }
  }
}

@media all and (max-width: 767px) {
  .login-page .login-form .login-form-sub {
    min-width: 290px;
    max-width: 290px;
  }
}
