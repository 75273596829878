:root {
  --color-white: #fff;
  --color-off-white: #f3f6f9;
  --color-dark-white: #e5e5e5;
  --color-black: #000000;
  --color-light-black: #191919;
  --color-grey: #777777;
  --color-light-grey: #cbcbcb;
  --color-apache: #ddb565;
  // --color-apache: linear-gradient(to bottom, #ff9500 50%, #ff5e3a 100%);
  --color-orange-v2: #ff9500;
  --color-yellow-v2: #ff5e3a;
  --color-apache-solid: #ddb565;
  --color-apache-light: #fcf7ef;
  --color-brown: #5b3e00;
  --color-green: #005348;
  --color-light-green: #00a130;
  --color-transparent: #ff000000;
  --body-bg: #f2f2f2;
  --color-table-grey: #f5f5f5;
  --color-red: #a10000;
  --color-orange: #ffaa00;
  --color-yellow: #d6a32b;
}

.dark-white {
  color: var(--color-light-grey);
}

.white {
  color: var(--color-white);
}

.black {
  color: var(--color-black);
}

.light-black {
  color: var(--color-light-black);
}

.brown {
  color: var(--color-brown);
}

.grey {
  color: var(--color-grey);
}

.green {
  color: var(--color-green);
}

.red {
  color: var(--color-red);
}

.light-green {
  color: var(--color-light-green);
}

.orange {
  color: var(--color-orange);
}

.yellow {
  color: var(--color-yellow);
}

.apache {
  color: var(--color-apache);
}

.body-bg {
  background-color: var(--body-bg);
}

.bg-apache {
  background-color: rgba(221, 181, 101, 0.1);
}
.bg-gray {
  background-color: rgba(100, 98, 95, 0.1);
}
.bg-transparent {
  background-color: transparent;
}
.gradient-apache {
  background-image: var(--color-apache);
}
