@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Quicksand:wght@300;400;500;600;700&display=swap');
:root {
    --font-family--montserrat-sans-serif: "Montserrat", Helvetica, Arial, sans-serif;
    --font-family-quicksand-sans-serif: "Quicksand", Helvetica, Arial, sans-serif;
    
    // --font-family-base: var( --font-family--montserrat-sans-serif);
    // --font-family-hind: var(--font-family-hind-sans-serif);
}

.website-body{
    --font-family-base: var(--font-family-quicksand-sans-serif);
    --font-family-montserrat: var(--font-family--montserrat-sans-serif);
    --font-size-base: 1rem;
    --font-size-large: 3.125rem;
    --font-size-md: 1.875rem; 
    --font-size-small: 0.875rem;
    --font-size-smaller: .75rem;
    --font-size-xs: .575rem;
    --font-size-smallest: .35rem;

    --line-height-base: 20px;
    --line-height-medium: 24px;

    .mon{
        font-family: var(--font-family-base);   
    }
    
    .montserrat{
        font-family: var(--font-family-montserrat);   
    }
    
    .hind{
        font-family: var(--font-family-montserrat);
    }
    
    .raleway{
        font-family: var(--font-family-montserrat);
    }
    
    .fw-medium{
        font-weight: 500;
    }
    
    .fw-semi-bold{
        font-weight: 700;
    }
    
    .fw-ex-bold{
        font-weight: 800;
    }
    
    .lh-18{
        line-height: calc(var(--line-height-base) - 2px);
    }
    
    .lh-20{
        line-height: var(--line-height-base);
    }
    
    .lh-24{
        line-height: calc(var(--line-height-base) + 4px);
    }
    
    .lh-26{
        line-height: calc(var(--line-height-base) + 6px);
    }
    
    .lh-28{
        line-height: calc(var(--line-height-base) + 8px);
    }
    
    .lh-30{
        line-height: calc(var(--line-height-base) + 10px);
    }
    
    .lh-35{
        line-height: calc(var(--line-height-base) + 15px);
    }
    
    .lh-64{
        line-height: 64px;
    }
    
    
    .fs-12{
        font-size: calc(var(--font-size-base) - 0.25rem);
    }
    
    .fs-13{
        font-size: calc(var(--font-size-base) - 0.1875rem);
    }
    
    .fs-14{
        font-size: calc(var(--font-size-base) - 0.125rem);
    }
    
    .fs-15{
        font-size: calc(var(--font-size-base) - 0.0625rem);
    }
    
    .fs-16{
        // font-size: var(--font-size-base);
        font-size: calc(var(--font-size-base) + 0.125rem);
    }
    
    .fs-18{
        font-size: calc(var(--font-size-base) + 0.125rem);
    }
    
    .fs-20{
        font-size: calc(var(--font-size-base) + 0.25rem);
    }
    
    .fs-22{
        font-size: calc(var(--font-size-base) + 0.375rem);
    }
    
    .fs-24{
        font-size: calc(var(--font-size-md) - 0.375rem);
    }
    
    .fs-28{
        font-size: calc(var(--font-size-md) - 0.125rem);
    }
    
    .fs-30{
        font-size: var(--font-size-md);
    }
    
    .fs-32{
        font-size: calc(var(--font-size-md) + 0.125rem);
    }
    
    .fs-34{
        font-size: calc(var(--font-size-md) + 0.25rem);
    }
    
    .fs-38{
        font-size: calc(var(--font-size-large) - 0.75rem);
    }
    
    .fs-42{
        font-size: calc(var(--font-size-large) - 0.5rem);
    }
    
    .fs-50{
        font-size: var(--font-size-large);
    }
    
    .fs-60{
        font-size: calc(var(--font-size-large) + 0.625rem);
    }
}