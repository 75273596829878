.home-body-sub {
  padding-top: 83px;
}

.section-title {
  display: inline-block;
  padding-bottom: 0.5rem;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    bottom: 0;
    left: 25%;
    border-bottom: 5px solid var(--color-apache);
  }
}

section.header {
  background: url(../assets/img/header-bg.svg) no-repeat, var(--color-green);
  background-size: cover;
  .navbar {
    padding: 15px;
    .navbar-nav {
      .nav-link {
        color: var(--color-white);
        font-family: var(--font-family-lato);
        font-weight: 500;
        font-size: calc(var(--font-size-base) + 2px);
        &:last-child {
          background-color: var(--color-apache);
          color: var(--color-brown);
          border-radius: 4px;
          margin-left: 1.25rem;
          padding-right: 2rem;
          padding-left: 2rem;
        }
        &:not(:last-child) {
          &:hover,
          &.active {
            // color: var(--color-apache);
            background-color: transparent;
            background-image: linear-gradient(
              180deg,
              #ff9500 50%,
              #ff5e3a 100%
            );
            transition: all 0.3s ease-out;
          }
        }
      }
    }
    &.active {
      background-color: var(--color-white) !important;
      border-bottom: 1px solid #ccc;
      .nav-link:not(:last-child) {
        color: var(--color-light-black);
      }
    }
  }
}

section.about-us {
  background-color: var(--color-white);
  .section-title {
    &::before {
      left: 0;
    }
  }
  .about-bg {
    background: transparent linear-gradient(120deg, #07231e 0%, #005348 100%) 0%
      0% no-repeat padding-box;
    border-radius: 210px 0 0 210px;
  }
}

section.recently-added {
  .card {
    box-shadow: 3px 3px 6px #00000033;
    border-radius: 10px;
    border: unset;
    .section-title {
      &:before {
        border-width: 3px;
      }
    }
  }
}

footer.home {
  background: url(../assets/img/footer-bg.png) no-repeat, var(--color-green);
  background-size: cover;
  .section-title::before {
    left: 0;
    border-bottom: 3px solid var(--color-apache);
  }
  .social {
    li {
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }
  .quick-links {
    li {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      a {
        color: var(--color-white);
        font-size: var(--font-size-base);
        &:hover {
          color: var(--color-apache);
          transition: all 0.3s ease;
        }
      }
    }
  }
  .footer-strip {
    border-top: 1px solid var(--color-apache);
  }
}

.home-modal {
  .modal-body-sub {
    height: 400px;
    overflow-y: auto;
    @include smallscroll;
  }
  .modal-content {
    border: 1px solid #cbcbcb;
  }
}

@media (min-width: 1200px) {
  section.about-us {
    .about-bg {
      padding: 4.5rem 7rem;
    }
  }
  section.about-us {
    p.w-82 {
      width: 82%;
    }
  }
}

@media (min-width: 992px) {
  section.header {
    .navbar {
      .navbar-nav {
        .nav-link {
          padding-right: 1.25rem;
          padding-left: 1.25rem;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  section.header {
    .header-content {
      min-height: 575px;
    }
  }
}

@media (max-width: 1200px) {
  section.about-us .about-bg {
    padding: 5rem 2rem 5rem 7rem;
  }
}

@media (max-width: 767px) {
  section.header {
    .header-content {
      padding: 3rem 15px;
      text-align: center;
    }
  }
}
